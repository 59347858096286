function getCookieValue(name: string) {
  return document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
}

enum ApolloEnvironment {
  LOCAL = 'local',
  STAGING = 'staging',
  PRODUCTION = 'production'
}

export const getEnv = (): ApolloEnvironment => {
  const buildenv = getCookieValue('buildenv');

  if (buildenv === 'PRODUCTION') return ApolloEnvironment.PRODUCTION;
  if (buildenv === 'STAGING') return ApolloEnvironment.STAGING;
  return ApolloEnvironment.LOCAL;
};

export const isDevEnv = () => process.env.NODE_ENV === 'development';

export const isStaging = () => getEnv() === 'staging';

export const isProduction = () => getEnv() === 'production';
