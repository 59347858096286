import { get, throttle } from 'lodash-es';
import { shouldAuthBypass, getAuthBypassToken } from '@/auth/authBypass';
import { HTTPError } from 'ky';

let lastBuildVersion = null;
const alertWhenServerInstanceChanges = throttle(
  () => alert('The server has restarted; please complete your work and reload the page as soon as possible'),
  300000
);

export const authHeaderHook = (request, options) => {
  request.headers.set('Accept', 'application/json, text/html, */*');
  if (shouldAuthBypass()) {
    request.headers.set('X-Dev-Token', getAuthBypassToken());
    options.credentials = 'omit';
  }
};

const formatErrorResponse = async errorResponse => {
  const isProduction = process.env.NODE_ENV === 'production';
  const isApiError = !(errorResponse.status === 500);
  const errorStatus = `${errorResponse.status} ${errorResponse.statusText}`;

  const errorMessages = [];

  try {
    const json = await errorResponse.json();
    const errorId = get(json, 'errorId', 'UNKNOWN');
    const code = get(json, 'code', '');
    const message = get(json, 'message', json);
    const details = isApiError ? get(json, 'userMessage') : get(json, 'stacktrace', []).slice(0, 10).join('\n');

    errorMessages.push(message, errorStatus);

    if (isProduction) {
      errorMessages.push(`Error code: ${errorId} ${code}`);
    } else {
      errorMessages.push(details);
    }
  } catch (e) {
    errorMessages.push(errorStatus, 'Something went Wrong');
    console.error('error retrieving response JSON', e);
  }

  const errorMessageText = errorMessages.join('\n\n');

  if (!isApiError) {
    console.error(errorMessageText);
  } else {
    console.warn(errorMessageText);
  }

  return errorMessageText;
};

export const throwErrorMessageHook = async (request, options, response) => {
  const clonedResponse = response.clone();
  if (!response.ok) {
    const errorMessage = await formatErrorResponse(response);
    const httpError = new HTTPError(response, request, options);
    // override ky generated error message with our custom error messasge
    httpError.message = errorMessage;

    throw httpError;
  }
  return clonedResponse;
};

export const alertErrorMessageHook = async (request, options, response) => {
  const clonedResponse = response.clone();
  if (!response.ok) {
    const errorMessage = await formatErrorResponse(response);
    alert(errorMessage);
  }
  return clonedResponse;
};

// redirect to login path on auth failure in dev
export const devAuthRedirectHook = (request, options, response) => {
  const isDev = process.env.NODE_ENV === 'development';
  const isAuthFailure = get(response, 'status') === 401;
  // if we get redirected, authorize
  if (isDev && isAuthFailure) {
    const { pathname } = window.location;
    window.location.href = `/login?redirect=${pathname}`;
  }
  return response;
};

const errorMessageAndCodeResponse = async errorResponse => {
  const errorMessages = [];
  let code = 'UNKNOWN_ERROR';

  try {
    const json = await errorResponse.json();
    const message = get(json, 'message');
    code = get(json, 'code', 'UNKNOWN_ERROR');

    errorMessages.push(message);
  } catch (e) {
    errorMessages.push('Something went Wrong');
    console.error('error retrieving response JSON', e);
  }

  const errorMessageText = errorMessages.join('\n\n');

  return { code, message: errorMessageText };
};

// check if the build version has changed and take action if so
//  if the reloadOnBuildVersionChange option has been provided to this
//  http call, the page will be reloaded if a new build version is detected
export const buildVersionHook = (request, options, response) => {
  // the build version reported by this request response
  const currentBuildVersion = response.headers.get('Build-Version');

  // the Build-Version header is not provided when an error occurs,
  //  in which case we cannot check for a version change
  if (!currentBuildVersion) {
    return response;
  }

  // if we haven't persisted the build version yet, do so
  if (lastBuildVersion === null) {
    lastBuildVersion = currentBuildVersion;
    return response;
  }

  // check if the build version has changed
  if (lastBuildVersion !== currentBuildVersion) {
    // if the reloadOnBuildVersionChange option is set, reload the page
    if (options.reloadOnBuildVersionChange) {
      window.location.reload();
      return undefined;
    }
    // just alert
    alertWhenServerInstanceChanges();
  }

  return response;
};

export const throwErrorMessageAndCodeHook = async (request, options, response) => {
  const clonedResponse = response.clone();
  if (!response.ok) {
    const { message, code } = await errorMessageAndCodeResponse(response);
    const httpError = new HTTPError(response, request, options);
    // override ky generated error message with our custom error messasge
    httpError.message = message;
    httpError.code = code;

    throw httpError;
  }
  return clonedResponse;
};
